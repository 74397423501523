import { graphql } from 'react-relay';
import Util from '../../forecast-app/shared/util/util';
import { trackAndCommitMutation } from '../../tracking/amplitude/TrackMutation';
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const handleAssignPersonToTaskErrors = (response) => {
    var _a;
    const errors = (_a = response.assignPersonToTask) === null || _a === void 0 ? void 0 : _a.errors;
    if (errors && errors.length === 1) {
        return Util.checkForSageErrorAndShowModal(errors, false);
    }
    return false;
};
const mutation = graphql `
	mutation AssignPersonToTaskMutation($input: AssignPersonToTaskInput!) {
		assignPersonToTask(input: $input) {
			errors
			task {
				id
				role {
					id
					name
				}
				assignedPersons {
					id
				}
			}
		}
	}
`;
// Imperative updater since assignedPersons does not follow relay connection paradigm. With a connection, RANGE_ADD would be usable instead.
// Alternatively could pass in full list of assignees and manipulate with standard optimistic updater, but feels more correct to allow mutation to run in isolation.
const optimisticUpdater = (input, store) => {
    var _a;
    if (!input.taskId || !input.personId)
        return;
    const taskNode = store.get(input.taskId);
    const existingAssignedPersons = (_a = taskNode === null || taskNode === void 0 ? void 0 : taskNode.getLinkedRecords('assignedPersons')) !== null && _a !== void 0 ? _a : [];
    const newAssignedPerson = store.get(input.personId);
    if (newAssignedPerson) {
        const newAssignedPersons = [...existingAssignedPersons, newAssignedPerson];
        taskNode === null || taskNode === void 0 ? void 0 : taskNode.setLinkedRecords(newAssignedPersons, 'assignedPersons');
    }
};
function commit(environment, input, onSuccess, onError) {
    const mutationConfig = {
        mutation,
        variables: { input: Object.assign({ csrfToken: Util.getCsrfValue(), socketClientId: Util.getClientId() }, input) },
        optimisticUpdater: store => optimisticUpdater(input, store),
        onCompleted: onSuccess,
        onError: onError,
    };
    return trackAndCommitMutation(environment, mutationConfig);
}
export default { commit };
