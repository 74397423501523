/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type HideSageSyncAnalysisIssuesInput = {|
  ids?: ?$ReadOnlyArray<?string>,
  csrfToken?: ?string,
  socketClientId?: ?string,
  clientMutationId?: ?string,
|};
export type hideSageSyncAnalysisIssuesMutationVariables = {|
  input: HideSageSyncAnalysisIssuesInput
|};
export type hideSageSyncAnalysisIssuesMutationResponse = {|
  +hideSageSyncAnalysisIssues: ?{|
    +ids: ?$ReadOnlyArray<?string>
  |}
|};
export type hideSageSyncAnalysisIssuesMutation = {|
  variables: hideSageSyncAnalysisIssuesMutationVariables,
  response: hideSageSyncAnalysisIssuesMutationResponse,
|};
*/


/*
mutation hideSageSyncAnalysisIssuesMutation(
  $input: HideSageSyncAnalysisIssuesInput!
) {
  hideSageSyncAnalysisIssues(input: $input) {
    ids
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "HideSageSyncAnalysisIssuesInput!"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "HideSageSyncAnalysisIssuesPayload",
    "kind": "LinkedField",
    "name": "hideSageSyncAnalysisIssues",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ids",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "hideSageSyncAnalysisIssuesMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "hideSageSyncAnalysisIssuesMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "hideSageSyncAnalysisIssuesMutation",
    "operationKind": "mutation",
    "text": "mutation hideSageSyncAnalysisIssuesMutation(\n  $input: HideSageSyncAnalysisIssuesInput!\n) {\n  hideSageSyncAnalysisIssues(input: $input) {\n    ids\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '58d9258aa946517db6b28ac02a1c5d25';

module.exports = node;
