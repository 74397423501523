import Util from '../../forecast-app/shared/util/util';
import { commitMutation, graphql } from 'react-relay';
import { trackEvent } from '../../tracking/amplitude/TrackingV2';
import { createRangeBulkAddUpdater } from '../../relay-helpers/RelayCacheHelpers';
const mutation = graphql `
	mutation bulkCreatePersonsMutation($input: BulkCreatePersonsInput!) {
		bulkCreatePersons(input: $input) {
			allPersons {
				node {
					id
					createdAt
					createdBy
					harvestUser
					economicUser
					initials
					seat
					gitlabUser {
						integrationUserId
					}
					gitlabServerUser {
						integrationUserId
					}
					githubUser {
						integrationUserId
					}
					jiraServerUser
					jiraCloudUser
					asanaUser
					adoUserId
					unit4User
					msTeamsId
					bambooHRId
					sageIntacctId
					slackId
					firstName
					lastName
					fullName
					email
					startDate
					endDate
					profilePictureId
					profilePictureDefaultId
					role {
						id
						name
					}
					skillPersons {
						skill {
							id
						}
						level {
							id
						}
					}
					profiles(first: 10000) {
						edges {
							node {
								id
								name
								seat
							}
						}
					}
					permissions
					active
					invited
					isViewer
					costPeriods(first: 10000) @connection(key: "Person_costPeriods", filters: []) {
						edges {
							node {
								id
								startDay
								startMonth
								startYear
								cost
							}
						}
					}
					department {
						id
						name
					}
					hasLoggedIn
				}
			}
			company {
				userSeats
				virtualSeats
			}
			errors
		}
	}
`;
function commit(environment, input, onSuccess, onError) {
    trackEvent('Employees', 'Bulk Created', input);
    const variables = { input: Object.assign({ csrfToken: Util.getCsrfValue(), socketClientId: Util.getClientId() }, input) };
    return commitMutation(environment, {
        mutation,
        variables,
        updater: createRangeBulkAddUpdater(input.companyId || '', 'bulkCreatePersons', 'allPersons', 'Company_allPersons'),
        onCompleted: onSuccess,
        onError: onError,
    });
}
export default { commit };
