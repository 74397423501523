import Util from '../../forecast-app/shared/util/util';
import { commitMutation, graphql } from 'react-relay';
import { trackEvent } from '../../tracking/amplitude/TrackingV2';
import { createRangeBulkAddUpdater } from '../../relay-helpers/RelayCacheHelpers';
const mutation = graphql `
	mutation bulkCreateDepartmentsMutation($input: BulkCreateDepartmentsInput!) {
		bulkCreateDepartments(input: $input) {
			departments {
				node {
					id
					name
				}
			}
			errors
		}
	}
`;
function commit(environment, input, onSuccess, onError) {
    trackEvent('Departmetns', 'Bulk Created', input);
    const variables = { input: Object.assign({ csrfToken: Util.getCsrfValue(), socketClientId: Util.getClientId() }, input) };
    return commitMutation(environment, {
        mutation,
        variables,
        updater: createRangeBulkAddUpdater(input.companyId || '', 'bulkCreateDepartments', 'departments', 'Company_departments'),
        onCompleted: onSuccess,
        onError: onError,
    });
}
export default { commit };
