import Util from '../forecast-app/shared/util/util';
import {commitMutation, graphql} from 'react-relay';

const mutation = graphql`
	mutation activateWorkatoIntegrationMutation($input: ActivateWorkatoIntegrationInput!) {
		activateWorkatoIntegration(input: $input) {
			outcome
			company {
				id
				workatoCustomerId
				workatoIframeToken
				hiBobEnabled
				workatoForecastHiBobConnectionId
				workatoHiBobConnectionId
				sageIntacctEnabled
				workatoForecastSageIntacctConnectionId
				workatoSageIntacctConnectionId
			}
		}
	}
`;

function commit(environment, input, onSuccess, onError) {
	return commitMutation(environment, {
		mutation,
		variables: {input: {csrfToken: Util.getCsrfValue(), socketClientId: Util.getClientId(), ...input}},
		configs: [],
		onCompleted: onSuccess,
		onError: onError,
	});
}

export default {commit};
