/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/*
mutation bulkCreatePersonsMutation(
  $input: BulkCreatePersonsInput!
) {
  bulkCreatePersons(input: $input) {
    allPersons {
      node {
        id
        createdAt
        createdBy
        harvestUser
        economicUser
        initials
        seat
        gitlabUser {
          integrationUserId
        }
        gitlabServerUser {
          integrationUserId
        }
        githubUser {
          integrationUserId
        }
        jiraServerUser
        jiraCloudUser
        asanaUser
        adoUserId
        unit4User
        msTeamsId
        bambooHRId
        sageIntacctId
        slackId
        firstName
        lastName
        fullName
        email
        startDate
        endDate
        profilePictureId
        profilePictureDefaultId
        role {
          id
          name
        }
        skillPersons {
          skill {
            id
          }
          level {
            id
          }
          id
        }
        profiles(first: 10000) {
          edges {
            node {
              id
              name
              seat
            }
          }
        }
        permissions
        active
        invited
        isViewer
        costPeriods(first: 10000) {
          edges {
            node {
              id
              startDay
              startMonth
              startYear
              cost
              __typename
            }
            cursor
          }
          pageInfo {
            endCursor
            hasNextPage
          }
        }
        department {
          id
          name
        }
        hasLoggedIn
      }
    }
    company {
      userSeats
      virtualSeats
      id
    }
    errors
  }
}
*/
const node = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "input",
            "type": "BulkCreatePersonsInput!"
        }
    ], v1 = [
        {
            "kind": "Variable",
            "name": "input",
            "variableName": "input"
        }
    ], v2 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    }, v3 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "createdAt",
        "storageKey": null
    }, v4 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "createdBy",
        "storageKey": null
    }, v5 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "harvestUser",
        "storageKey": null
    }, v6 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "economicUser",
        "storageKey": null
    }, v7 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "initials",
        "storageKey": null
    }, v8 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "seat",
        "storageKey": null
    }, v9 = [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "integrationUserId",
            "storageKey": null
        }
    ], v10 = {
        "alias": null,
        "args": null,
        "concreteType": "IntegrationUserType",
        "kind": "LinkedField",
        "name": "gitlabUser",
        "plural": false,
        "selections": (v9 /*: any*/),
        "storageKey": null
    }, v11 = {
        "alias": null,
        "args": null,
        "concreteType": "IntegrationUserType",
        "kind": "LinkedField",
        "name": "gitlabServerUser",
        "plural": false,
        "selections": (v9 /*: any*/),
        "storageKey": null
    }, v12 = {
        "alias": null,
        "args": null,
        "concreteType": "IntegrationUserType",
        "kind": "LinkedField",
        "name": "githubUser",
        "plural": false,
        "selections": (v9 /*: any*/),
        "storageKey": null
    }, v13 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "jiraServerUser",
        "storageKey": null
    }, v14 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "jiraCloudUser",
        "storageKey": null
    }, v15 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "asanaUser",
        "storageKey": null
    }, v16 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "adoUserId",
        "storageKey": null
    }, v17 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "unit4User",
        "storageKey": null
    }, v18 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "msTeamsId",
        "storageKey": null
    }, v19 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "bambooHRId",
        "storageKey": null
    }, v20 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "sageIntacctId",
        "storageKey": null
    }, v21 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "slackId",
        "storageKey": null
    }, v22 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "firstName",
        "storageKey": null
    }, v23 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "lastName",
        "storageKey": null
    }, v24 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "fullName",
        "storageKey": null
    }, v25 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "email",
        "storageKey": null
    }, v26 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "startDate",
        "storageKey": null
    }, v27 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "endDate",
        "storageKey": null
    }, v28 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "profilePictureId",
        "storageKey": null
    }, v29 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "profilePictureDefaultId",
        "storageKey": null
    }, v30 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
    }, v31 = [
        (v2 /*: any*/),
        (v30 /*: any*/)
    ], v32 = {
        "alias": null,
        "args": null,
        "concreteType": "Role",
        "kind": "LinkedField",
        "name": "role",
        "plural": false,
        "selections": (v31 /*: any*/),
        "storageKey": null
    }, v33 = [
        (v2 /*: any*/)
    ], v34 = {
        "alias": null,
        "args": null,
        "concreteType": "Skill",
        "kind": "LinkedField",
        "name": "skill",
        "plural": false,
        "selections": (v33 /*: any*/),
        "storageKey": null
    }, v35 = {
        "alias": null,
        "args": null,
        "concreteType": "SkillLevel",
        "kind": "LinkedField",
        "name": "level",
        "plural": false,
        "selections": (v33 /*: any*/),
        "storageKey": null
    }, v36 = [
        {
            "kind": "Literal",
            "name": "first",
            "value": 10000
        }
    ], v37 = {
        "alias": null,
        "args": (v36 /*: any*/),
        "concreteType": "ProfileTypeConnection",
        "kind": "LinkedField",
        "name": "profiles",
        "plural": false,
        "selections": [
            {
                "alias": null,
                "args": null,
                "concreteType": "ProfileTypeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "Profile",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                            (v2 /*: any*/),
                            (v30 /*: any*/),
                            (v8 /*: any*/)
                        ],
                        "storageKey": null
                    }
                ],
                "storageKey": null
            }
        ],
        "storageKey": "profiles(first:10000)"
    }, v38 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "permissions",
        "storageKey": null
    }, v39 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "active",
        "storageKey": null
    }, v40 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "invited",
        "storageKey": null
    }, v41 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "isViewer",
        "storageKey": null
    }, v42 = [
        {
            "alias": null,
            "args": null,
            "concreteType": "CostPeriodTypeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "CostPeriod",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                        (v2 /*: any*/),
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "startDay",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "startMonth",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "startYear",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "cost",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "__typename",
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "cursor",
                    "storageKey": null
                }
            ],
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "endCursor",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasNextPage",
                    "storageKey": null
                }
            ],
            "storageKey": null
        }
    ], v43 = {
        "alias": null,
        "args": null,
        "concreteType": "DepartmentType",
        "kind": "LinkedField",
        "name": "department",
        "plural": false,
        "selections": (v31 /*: any*/),
        "storageKey": null
    }, v44 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "hasLoggedIn",
        "storageKey": null
    }, v45 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "userSeats",
        "storageKey": null
    }, v46 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "virtualSeats",
        "storageKey": null
    }, v47 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "errors",
        "storageKey": null
    };
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "bulkCreatePersonsMutation",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "BulkCreatePersonsPayload",
                    "kind": "LinkedField",
                    "name": "bulkCreatePersons",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "PersonTypeEdge",
                            "kind": "LinkedField",
                            "name": "allPersons",
                            "plural": true,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Person",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                        (v2 /*: any*/),
                                        (v3 /*: any*/),
                                        (v4 /*: any*/),
                                        (v5 /*: any*/),
                                        (v6 /*: any*/),
                                        (v7 /*: any*/),
                                        (v8 /*: any*/),
                                        (v10 /*: any*/),
                                        (v11 /*: any*/),
                                        (v12 /*: any*/),
                                        (v13 /*: any*/),
                                        (v14 /*: any*/),
                                        (v15 /*: any*/),
                                        (v16 /*: any*/),
                                        (v17 /*: any*/),
                                        (v18 /*: any*/),
                                        (v19 /*: any*/),
                                        (v20 /*: any*/),
                                        (v21 /*: any*/),
                                        (v22 /*: any*/),
                                        (v23 /*: any*/),
                                        (v24 /*: any*/),
                                        (v25 /*: any*/),
                                        (v26 /*: any*/),
                                        (v27 /*: any*/),
                                        (v28 /*: any*/),
                                        (v29 /*: any*/),
                                        (v32 /*: any*/),
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "SkillPerson",
                                            "kind": "LinkedField",
                                            "name": "skillPersons",
                                            "plural": true,
                                            "selections": [
                                                (v34 /*: any*/),
                                                (v35 /*: any*/)
                                            ],
                                            "storageKey": null
                                        },
                                        (v37 /*: any*/),
                                        (v38 /*: any*/),
                                        (v39 /*: any*/),
                                        (v40 /*: any*/),
                                        (v41 /*: any*/),
                                        {
                                            "alias": "costPeriods",
                                            "args": null,
                                            "concreteType": "CostPeriodTypeConnection",
                                            "kind": "LinkedField",
                                            "name": "__Person_costPeriods_connection",
                                            "plural": false,
                                            "selections": (v42 /*: any*/),
                                            "storageKey": null
                                        },
                                        (v43 /*: any*/),
                                        (v44 /*: any*/)
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "Company",
                            "kind": "LinkedField",
                            "name": "company",
                            "plural": false,
                            "selections": [
                                (v45 /*: any*/),
                                (v46 /*: any*/)
                            ],
                            "storageKey": null
                        },
                        (v47 /*: any*/)
                    ],
                    "storageKey": null
                }
            ],
            "type": "Mutation"
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "bulkCreatePersonsMutation",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "BulkCreatePersonsPayload",
                    "kind": "LinkedField",
                    "name": "bulkCreatePersons",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "PersonTypeEdge",
                            "kind": "LinkedField",
                            "name": "allPersons",
                            "plural": true,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Person",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                        (v2 /*: any*/),
                                        (v3 /*: any*/),
                                        (v4 /*: any*/),
                                        (v5 /*: any*/),
                                        (v6 /*: any*/),
                                        (v7 /*: any*/),
                                        (v8 /*: any*/),
                                        (v10 /*: any*/),
                                        (v11 /*: any*/),
                                        (v12 /*: any*/),
                                        (v13 /*: any*/),
                                        (v14 /*: any*/),
                                        (v15 /*: any*/),
                                        (v16 /*: any*/),
                                        (v17 /*: any*/),
                                        (v18 /*: any*/),
                                        (v19 /*: any*/),
                                        (v20 /*: any*/),
                                        (v21 /*: any*/),
                                        (v22 /*: any*/),
                                        (v23 /*: any*/),
                                        (v24 /*: any*/),
                                        (v25 /*: any*/),
                                        (v26 /*: any*/),
                                        (v27 /*: any*/),
                                        (v28 /*: any*/),
                                        (v29 /*: any*/),
                                        (v32 /*: any*/),
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "SkillPerson",
                                            "kind": "LinkedField",
                                            "name": "skillPersons",
                                            "plural": true,
                                            "selections": [
                                                (v34 /*: any*/),
                                                (v35 /*: any*/),
                                                (v2 /*: any*/)
                                            ],
                                            "storageKey": null
                                        },
                                        (v37 /*: any*/),
                                        (v38 /*: any*/),
                                        (v39 /*: any*/),
                                        (v40 /*: any*/),
                                        (v41 /*: any*/),
                                        {
                                            "alias": null,
                                            "args": (v36 /*: any*/),
                                            "concreteType": "CostPeriodTypeConnection",
                                            "kind": "LinkedField",
                                            "name": "costPeriods",
                                            "plural": false,
                                            "selections": (v42 /*: any*/),
                                            "storageKey": "costPeriods(first:10000)"
                                        },
                                        {
                                            "alias": null,
                                            "args": (v36 /*: any*/),
                                            "filters": [],
                                            "handle": "connection",
                                            "key": "Person_costPeriods",
                                            "kind": "LinkedHandle",
                                            "name": "costPeriods"
                                        },
                                        (v43 /*: any*/),
                                        (v44 /*: any*/)
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "Company",
                            "kind": "LinkedField",
                            "name": "company",
                            "plural": false,
                            "selections": [
                                (v45 /*: any*/),
                                (v46 /*: any*/),
                                (v2 /*: any*/)
                            ],
                            "storageKey": null
                        },
                        (v47 /*: any*/)
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "id": null,
            "metadata": {
                "connection": [
                    {
                        "count": null,
                        "cursor": null,
                        "direction": "forward",
                        "path": null
                    }
                ]
            },
            "name": "bulkCreatePersonsMutation",
            "operationKind": "mutation",
            "text": "mutation bulkCreatePersonsMutation(\n  $input: BulkCreatePersonsInput!\n) {\n  bulkCreatePersons(input: $input) {\n    allPersons {\n      node {\n        id\n        createdAt\n        createdBy\n        harvestUser\n        economicUser\n        initials\n        seat\n        gitlabUser {\n          integrationUserId\n        }\n        gitlabServerUser {\n          integrationUserId\n        }\n        githubUser {\n          integrationUserId\n        }\n        jiraServerUser\n        jiraCloudUser\n        asanaUser\n        adoUserId\n        unit4User\n        msTeamsId\n        bambooHRId\n        sageIntacctId\n        slackId\n        firstName\n        lastName\n        fullName\n        email\n        startDate\n        endDate\n        profilePictureId\n        profilePictureDefaultId\n        role {\n          id\n          name\n        }\n        skillPersons {\n          skill {\n            id\n          }\n          level {\n            id\n          }\n          id\n        }\n        profiles(first: 10000) {\n          edges {\n            node {\n              id\n              name\n              seat\n            }\n          }\n        }\n        permissions\n        active\n        invited\n        isViewer\n        costPeriods(first: 10000) {\n          edges {\n            node {\n              id\n              startDay\n              startMonth\n              startYear\n              cost\n              __typename\n            }\n            cursor\n          }\n          pageInfo {\n            endCursor\n            hasNextPage\n          }\n        }\n        department {\n          id\n          name\n        }\n        hasLoggedIn\n      }\n    }\n    company {\n      userSeats\n      virtualSeats\n      id\n    }\n    errors\n  }\n}\n"
        }
    };
})();
node.hash = 'c44e64dfd802053e8deb7cfbd5858838';
export default node;
