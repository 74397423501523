import Util from '../forecast-app/shared/util/util';
import * as tracking from '../tracking';
import {graphql} from 'react-relay';
import {omit} from 'lodash';
import {hasFeatureFlag} from '../forecast-app/shared/util/FeatureUtil';
import {trackAndCommitMutation} from '../tracking/amplitude/TrackMutation';

const mutation = graphql`
	mutation duplicateProjectMutation($input: DuplicateProjectInput!) {
		duplicateProject(input: $input) {
			errors
			project {
				node {
					id
					companyProjectId
					customProjectId
					status
					name
					isJiraProject
					projectColor
					isInProjectGroup
					billable
					budgetType
					isInProjectGroup
					projectGroupId
					sprintTimeBox
					useManualAllocations
					harvestProject {
						id
					}
					statusColumnsV2(first: 1000000) {
						edges {
							node {
								id
								projectGroupStatusColumnId
							}
						}
					}
					projectPersons(first: 1000000, contactsOnly: true) {
						edges {
							node {
								id
								isContactPerson
								person {
									id
									firstName
									lastName
									profilePictureId
								}
							}
						}
					}
				}
			}
		}
	}
`;

function getConfigs() {
	return [
		{
			type: 'RANGE_ADD',
			parentID: 'Vmlld2VyOnFsZGZqYmUyNDM0UlpSRmVlcmc=',
			connectionInfo: [
				{
					key: 'Viewer_projects',
					rangeBehavior: 'append',
				},
				{
					key: 'Header_Viewer_projects',
					rangeBehavior: 'append',
				},
			],
			edgeName: 'project',
		},
	];
}

function commit(environment, input, onSuccess, onError) {
	const trackingOptions = {
		_DuplicateSettings: input.duplicateSettings,
		_DuplicateTasks: input.duplicateTasks,
		_DuplicatePhases: input.duplicatePhases,
		_DuplicateProjectPersons: input.duplicateProjectPersons,
		_DuplicateAllocations: input.duplicateAllocations,
		_DuplicateExpenses: input.duplicateExpenses,
		_DuplicateRetainerPeriods: input.duplicateRetainerPeriods,
		_StartDateSet: !!input.projectStartYear,
		_EndDateSet: !!input.projectEndYear,
	};

	if (hasFeatureFlag('placeholders')) {
		trackingOptions._DuplicatePlaceholders = input.duplicatePlaceholders;
	}

	tracking.trackEvent('Project Duplicated', trackingOptions);

	const cleanedVariables = omit(input, ['labels', 'rateCardId']);
	return trackAndCommitMutation(environment, {
		mutation,
		variables: {input: {csrfToken: Util.getCsrfValue(), socketClientId: Util.getClientId(), ...cleanedVariables}},
		configs: getConfigs(),
		onCompleted: onSuccess,
		onError: onError,
	});
}

export default {commit};
