/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type STATUS_CATEGORY = "DONE" | "INPROGRESS" | "TODO" | "%future added value";
export type MergePhaseInput = {|
  id?: ?string,
  mergeFromPhaseId?: ?string,
  projectId?: ?string,
  csrfToken?: ?string,
  socketClientId?: ?string,
  clientMutationId?: ?string,
|};
export type MergePhaseMutationVariables = {|
  input: MergePhaseInput
|};
export type MergePhaseMutationResponse = {|
  +mergePhase: ?{|
    +phase: ?{|
      +id: string,
      +unit4Id: ?string,
      +jiraId: ?string,
    |},
    +project: ?{|
      +tasks: ?{|
        +edges: ?$ReadOnlyArray<?{|
          +node: ?{|
            +id: string,
            +startDay: ?number,
            +startMonth: ?number,
            +startYear: ?number,
            +startFrom: ?string,
            +deadlineDay: ?number,
            +deadlineMonth: ?number,
            +deadlineYear: ?number,
            +deadlineFrom: ?string,
            +taskLabels: ?$ReadOnlyArray<?{|
              +id: string,
              +label: ?{|
                +id: string,
                +name: ?string,
                +color: ?string,
              |},
            |}>,
            +phase: ?{|
              +id: string,
              +name: ?string,
            |},
            +project: ?{|
              +id: string,
              +estimationUnit: ?string,
              +minutesPerEstimationPoint: ?number,
            |},
            +statusColumnV2: ?{|
              +id: string,
              +name: ?string,
              +category: ?STATUS_CATEGORY,
            |},
            +assignedPersons: ?$ReadOnlyArray<?{|
              +id: string,
              +firstName: ?string,
              +lastName: ?string,
              +fullName: ?string,
              +active: ?boolean,
              +email: ?string,
              +profilePictureId: ?string,
              +profilePictureDefaultId: ?number,
              +permissions: ?$ReadOnlyArray<?string>,
            |}>,
          |}
        |}>
      |}
    |},
    +deletedPhaseId: ?string,
  |}
|};
export type MergePhaseMutation = {|
  variables: MergePhaseMutationVariables,
  response: MergePhaseMutationResponse,
|};
*/


/*
mutation MergePhaseMutation(
  $input: MergePhaseInput!
) {
  mergePhase(input: $input) {
    phase {
      id
      unit4Id
      jiraId
    }
    project {
      tasks(first: 10000) {
        edges {
          node {
            id
            startDay
            startMonth
            startYear
            startFrom
            deadlineDay
            deadlineMonth
            deadlineYear
            deadlineFrom
            taskLabels {
              id
              label {
                id
                name
                color
              }
            }
            phase {
              id
              name
            }
            project {
              id
              estimationUnit
              minutesPerEstimationPoint
            }
            statusColumnV2 {
              id
              name
              category
            }
            assignedPersons {
              id
              firstName
              lastName
              fullName
              active
              email
              profilePictureId
              profilePictureDefaultId
              permissions
            }
            __typename
          }
          cursor
        }
        pageInfo {
          endCursor
          hasNextPage
        }
      }
      id
    }
    deletedPhaseId
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "MergePhaseInput!"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "PhaseType",
  "kind": "LinkedField",
  "name": "phase",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "unit4Id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "jiraId",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "TaskTypeEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Task",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "startDay",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "startMonth",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "startYear",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "startFrom",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "deadlineDay",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "deadlineMonth",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "deadlineYear",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "deadlineFrom",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "TaskLabel",
            "kind": "LinkedField",
            "name": "taskLabels",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Label",
                "kind": "LinkedField",
                "name": "label",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "color",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PhaseType",
            "kind": "LinkedField",
            "name": "phase",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v4/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ProjectType",
            "kind": "LinkedField",
            "name": "project",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "estimationUnit",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "minutesPerEstimationPoint",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "StatusColumnV2",
            "kind": "LinkedField",
            "name": "statusColumnV2",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "category",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Person",
            "kind": "LinkedField",
            "name": "assignedPersons",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "firstName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "lastName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "fullName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "active",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "email",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "profilePictureId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "profilePictureDefaultId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "permissions",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "cursor",
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "PageInfo",
    "kind": "LinkedField",
    "name": "pageInfo",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "endCursor",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "hasNextPage",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
],
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "deletedPhaseId",
  "storageKey": null
},
v7 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 10000
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "MergePhaseMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "MergePhasePayload",
        "kind": "LinkedField",
        "name": "mergePhase",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "ProjectType",
            "kind": "LinkedField",
            "name": "project",
            "plural": false,
            "selections": [
              {
                "alias": "tasks",
                "args": null,
                "concreteType": "TaskTypeConnection",
                "kind": "LinkedField",
                "name": "__Project_tasks_connection",
                "plural": false,
                "selections": (v5/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v6/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "MergePhaseMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "MergePhasePayload",
        "kind": "LinkedField",
        "name": "mergePhase",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "ProjectType",
            "kind": "LinkedField",
            "name": "project",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v7/*: any*/),
                "concreteType": "TaskTypeConnection",
                "kind": "LinkedField",
                "name": "tasks",
                "plural": false,
                "selections": (v5/*: any*/),
                "storageKey": "tasks(first:10000)"
              },
              {
                "alias": null,
                "args": (v7/*: any*/),
                "filters": null,
                "handle": "connection",
                "key": "Project_tasks",
                "kind": "LinkedHandle",
                "name": "tasks"
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          (v6/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {
      "connection": [
        {
          "count": null,
          "cursor": null,
          "direction": "forward",
          "path": [
            "mergePhase",
            "project",
            "tasks"
          ]
        }
      ]
    },
    "name": "MergePhaseMutation",
    "operationKind": "mutation",
    "text": "mutation MergePhaseMutation(\n  $input: MergePhaseInput!\n) {\n  mergePhase(input: $input) {\n    phase {\n      id\n      unit4Id\n      jiraId\n    }\n    project {\n      tasks(first: 10000) {\n        edges {\n          node {\n            id\n            startDay\n            startMonth\n            startYear\n            startFrom\n            deadlineDay\n            deadlineMonth\n            deadlineYear\n            deadlineFrom\n            taskLabels {\n              id\n              label {\n                id\n                name\n                color\n              }\n            }\n            phase {\n              id\n              name\n            }\n            project {\n              id\n              estimationUnit\n              minutesPerEstimationPoint\n            }\n            statusColumnV2 {\n              id\n              name\n              category\n            }\n            assignedPersons {\n              id\n              firstName\n              lastName\n              fullName\n              active\n              email\n              profilePictureId\n              profilePictureDefaultId\n              permissions\n            }\n            __typename\n          }\n          cursor\n        }\n        pageInfo {\n          endCursor\n          hasNextPage\n        }\n      }\n      id\n    }\n    deletedPhaseId\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'b7c3effa9511039362d200059c0b1dfc';

module.exports = node;
