import {graphql} from 'react-relay';
import Util from '../../forecast-app/shared/util/util';
import {trackAndCommitMutation} from '../../tracking/amplitude/TrackMutation';

const mutation = graphql`
	mutation fixSageSyncAnalysisIssuesMutation($input: FixSageSyncAnalysisIssuesInput!) {
		fixSageSyncAnalysisIssues(input: $input) {
			ids
		}
	}
`;

function commit(environment, input, onSuccess, onError) {
	const variables = {input: {csrfToken: Util.getCsrfValue(), socketClientId: Util.getClientId(), ...input}};

	return trackAndCommitMutation(environment, {
		mutation,
		variables,
		configs: [],
		onCompleted: onSuccess,
		onError: onError,
	});
}

export default {commit};
