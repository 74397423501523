/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DeactivateHiBobInput = {|
  csrfToken?: ?string,
  socketClientId?: ?string,
  clientMutationId?: ?string,
|};
export type deactivateHiBobMutationVariables = {|
  input: DeactivateHiBobInput
|};
export type deactivateHiBobMutationResponse = {|
  +deactivateHiBob: ?{|
    +outcome: ?string,
    +company: ?{|
      +id: string,
      +workatoCustomerId: ?string,
      +workatoIframeToken: ?string,
      +hiBobEnabled: ?boolean,
      +workatoForecastHiBobConnectionId: ?string,
      +workatoHiBobConnectionId: ?string,
    |},
  |}
|};
export type deactivateHiBobMutation = {|
  variables: deactivateHiBobMutationVariables,
  response: deactivateHiBobMutationResponse,
|};
*/


/*
mutation deactivateHiBobMutation(
  $input: DeactivateHiBobInput!
) {
  deactivateHiBob(input: $input) {
    outcome
    company {
      id
      workatoCustomerId
      workatoIframeToken
      hiBobEnabled
      workatoForecastHiBobConnectionId
      workatoHiBobConnectionId
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "DeactivateHiBobInput!"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "DeactivateHiBobPayload",
    "kind": "LinkedField",
    "name": "deactivateHiBob",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "outcome",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Company",
        "kind": "LinkedField",
        "name": "company",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "workatoCustomerId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "workatoIframeToken",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "hiBobEnabled",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "workatoForecastHiBobConnectionId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "workatoHiBobConnectionId",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "deactivateHiBobMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "deactivateHiBobMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "deactivateHiBobMutation",
    "operationKind": "mutation",
    "text": "mutation deactivateHiBobMutation(\n  $input: DeactivateHiBobInput!\n) {\n  deactivateHiBob(input: $input) {\n    outcome\n    company {\n      id\n      workatoCustomerId\n      workatoIframeToken\n      hiBobEnabled\n      workatoForecastHiBobConnectionId\n      workatoHiBobConnectionId\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '16ddea54a20fadecb126b9858acd3763';

module.exports = node;
