/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/*
mutation bulkCreateDepartmentsMutation(
  $input: BulkCreateDepartmentsInput!
) {
  bulkCreateDepartments(input: $input) {
    departments {
      node {
        id
        name
      }
    }
    errors
  }
}
*/
const node = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "input",
            "type": "BulkCreateDepartmentsInput!"
        }
    ], v1 = [
        {
            "alias": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "input",
                    "variableName": "input"
                }
            ],
            "concreteType": "BulkCreateDepartmentsPayload",
            "kind": "LinkedField",
            "name": "bulkCreateDepartments",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "DepartmentTypeEdge",
                    "kind": "LinkedField",
                    "name": "departments",
                    "plural": true,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "DepartmentType",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "id",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "name",
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "errors",
                    "storageKey": null
                }
            ],
            "storageKey": null
        }
    ];
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "bulkCreateDepartmentsMutation",
            "selections": (v1 /*: any*/),
            "type": "Mutation"
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "bulkCreateDepartmentsMutation",
            "selections": (v1 /*: any*/)
        },
        "params": {
            "id": null,
            "metadata": {},
            "name": "bulkCreateDepartmentsMutation",
            "operationKind": "mutation",
            "text": "mutation bulkCreateDepartmentsMutation(\n  $input: BulkCreateDepartmentsInput!\n) {\n  bulkCreateDepartments(input: $input) {\n    departments {\n      node {\n        id\n        name\n      }\n    }\n    errors\n  }\n}\n"
        }
    };
})();
node.hash = '169a4c001c5c4c56b5bee5a79ac7dee2';
export default node;
